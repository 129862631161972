import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface URLContextProps {
  searchParams: UrlsProps;
  updateURL: (newSearchParams: UrlsProps) => void;
}
interface UrlsProps {
  search: string;
  pathname: string;
  hash: string;
}
interface URLSearchParamsProviderProps {
  children: ReactNode;
}

const URLSearchParamsContext = createContext<URLContextProps | undefined>(
  undefined
);

export function URLSearchParamsProvider({
  children,
}: URLSearchParamsProviderProps) {
  const [searchParams, setSearchParams] = useState<UrlsProps>({
    search: window.location.search,
    pathname: window.location.pathname,
    hash: window.location.hash,
  });

  const updateURL = (newSearchParams: UrlsProps) => {
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const handleLocationChange = () => {
      setSearchParams({
        search: window.location.search,
        pathname: window.location.pathname,
        hash: window.location.hash,
      });
    };

    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);
  return (
    <URLSearchParamsContext.Provider value={{ searchParams, updateURL }}>
      {children}
    </URLSearchParamsContext.Provider>
  );
}

export const useURLSearchParams = (): URLContextProps => {
  const context = useContext(URLSearchParamsContext);
  if (!context) {
    throw new Error(
      "useURLSearchParams must be used within a URLSearchParamsProvider"
    );
  }
  return context;
};
